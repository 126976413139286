.mail-table {
  margin: 50px;
  width: 40%;
}
.mail-table .yet-read-table {
  margin-bottom: 50px;
}
.mail-table .read-table {
  margin-bottom: 50px;
}

.report-table {
  margin: 50px;
  width: 40%;
}/*# sourceMappingURL=TopPage.css.map */