.main-content {
  margin-top: 100px;
}
.main-content .text {
  margin: auto;
  padding-left: 35%;
  padding-right: 35%;
}

@media screen and (max-width: 767px) {
  .main-content {
    margin-top: 100px;
  }
  .main-content .text {
    margin: auto;
    padding-left: 10%;
    padding-right: 10%;
  }
}/*# sourceMappingURL=LoginPage.css.map */