#order-table,
#memo-table {
    border-collapse: collapse;
    /*border: 2px solid rgb(140 140 140);*/
    font-family: sans-serif;
    font-size: 0.8rem;
    letter-spacing: 1px;
    width: 100%;
}

#order-table #order-thead,
#order-table #order-tfoot {
    background-color: darkgrey;
}

#order-table th,
#order-table td {
    border: 1px solid black;
    padding: 8px 10px;
    height: 35px;
}

#order-table .price {
    text-align: right;
    width: 150px;
}

#order-table .width-small {
    width: 50px;
}

#order-table .qty {
    width: 120px;
}

#order-table #amount-area {
    border: none;
}

#order-table .amount-title {
    background-color: darkgrey;
    font-weight: bold;
}

#memo-table {
    border: 1px solid black;
}
#memo-table #memo-title {
    background-color: darkgrey;
    border: 1px solid black;
    font-weight: bold;
    width: 100px;
    height: 100px;
}