.mail-table {
  margin: 50px;
  width: 40%;

  .yet-read-table {
    margin-bottom: 50px;
  }

  .read-table {
    margin-bottom: 50px;
  }
}

.report-table {
  margin: 50px;
  width: 40%;
}