.main-content {
  margin-top: 100px;

  .text {
    margin: auto;
    padding-left: 35%;
    padding-right: 35%;
  }
}

// mobile
@media screen and (max-width: 767px) {
  .main-content {
    margin-top: 100px;

    .text {
      margin: auto;
      padding-left: 10%;
      padding-right: 10%;
    }
  }
}